import React,{useState,useEffect} from 'react'
import { fetchClientCompleted } from '../../../services/auth-service/requestService';
import AlertMessage from '../../../components/shared/alertMessage/AlertMessage';


const CompleteSessions = ({initialValues}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [alertData, setAlertData] = React.useState(null);
    const clientId = initialValues._id;
    const [requests,setRequests]=useState([])
    
    const ClientRequests = async(clientId,page)=>{
            try {
              const response = await fetchClientCompleted(clientId,page);
              if(!response.status === 200){
                setAlertData({
                  message: 'failed to fetch Requests',
                });
              }else if(response===204){
               setAlertData({
                message: 'No Session History',
              });
              }else{
              setRequests(response.requests);
              setTotalPages(response.totalPages);}
            } catch (error) {
              console.log(error);
       }
    }
    
    useEffect(() => {
        // Fetch data when the component mounts or when currentPage changes
        ClientRequests(clientId, currentPage);
      }, [currentPage, clientId]);
      const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
      };
    
      const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure not to go below page 1
      };
      const handlePageClick = (page) => {
        setCurrentPage(page);
      };
    
      const renderPageButtons = () => {
        const buttons = [];
      
        const renderEllipsis = (id) => (
          <span key={`ellipsis-${id}`} className='ml-2 mt-2'>...</span>
        );
      
        for (let i = 1; i <= totalPages; i++) {
          if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 1) {
            // Display first, last, and nearby pages
            buttons.push(
              <button
                className={`btn btn-outline-primary mt-2 ml-2 ${i === currentPage ? 'active' : ''}`}
                key={i}
                onClick={() => handlePageClick(i)}
                disabled={i === currentPage}
              >
                {i}
              </button>
            );
          } else if (buttons[buttons.length - 1] !== renderEllipsis()) {
            // Display ellipsis if not already displayed
            buttons.push(renderEllipsis(i));
          }
        }
      
        return buttons;
      };

  return (
    <>
<div className="inflanar-profile-info mg-top-30 inflanar-profile-info__list-container">
<div className="inflanar-profile-info__head">
<h3 className="inflanar-profile-info__heading">Session History</h3></div>
<ul className="inflanar-profile-info__list inflanar-dflex-column list-none">

<li className="inflanar-dflex border border-primary-light rounded p-3">
<div className='col-lg-3 col-md-3 col-3'>
<h4 className="inflanar-profile-info__title text-dark">Order ID</h4>
</div>
<div className='col-lg-3 col-md-3 col-3'>
<h4 className="inflanar-profile-info__title text-dark">Name</h4>
</div>
<div className='col-lg-2 col-md-2 col-2'>
<h4 className="inflanar-profile-info__title text-dark">Session Date</h4>
</div>
<div className='col-lg-2 col-md-2 col-2 text-center'>
<h4 className="inflanar-profile-info__title text-dark">Fee</h4>
</div>
<div className='col-2'>
<h4 className="inflanar-profile-info__title text-dark">Status</h4>
<div className="ml-auto"> 
</div>
</div>
</li>


{requests.map((request) => (
    <li key={request._id} className="inflanar-dflex border border-primary-light p-3 rounded">
    <div className="col-lg-3 col-md-3 col-3">
    <h4 className="inflanar-profile-info__title">
    {request.razorpay_order_id.includes("order_") ? request.razorpay_order_id.split("order_")[1] : request.razorpay_order_id}
  </h4>        </div>
      <div className="col-lg-3 col-md-3 col-3">
        <h4 className="inflanar-profile-info__title">{request.influencer.firstName} {request.influencer.lastName}</h4>
      </div>
      <div className="col-lg-2 col-md-2 col-2">
        <h4 className="inflanar-profile-info__title">{new Date(request.selectedSlot.date).toLocaleDateString('en-GB')} <br></br>   {request.selectedSlot.startTime}-{request.selectedSlot.endTime}</h4>
      </div>
      <div className="col-lg-2 col-md-2 col-2 text-center">
        <h4 className="inflanar-profile-info__title">₹{Math.round((request.amount - (Math.round(request.platformfee * 0.18*100)/100 + request.platformfee))*100)/100}
        </h4>
      </div>
      <div className="col-2">
        <div className="ml-auto">
          <button className="btn btn-outline-success mt-1" disabled >Completed</button>
        </div>
      </div>
    </li>
  ))}
</ul>
<div className='text-right'>
<button className='btn btn-outline-primary mt-2' onClick={handlePrevPage} disabled={currentPage === 1}>
  Previous Page
</button>
{renderPageButtons()}
<button className='btn btn-outline-primary mt-2 ml-2'  onClick={handleNextPage} disabled={currentPage === totalPages}>
  Next Page
</button>
</div>
</div>
{alertData && (
  <div className="overlay">
    {/* You can add an overlay to dim the background if needed */}
    <AlertMessage
      message={alertData.message}
      type={alertData.type}
      onClose={() => setAlertData(null)}
    />
  </div>
)}
</>
  )
}

export default CompleteSessions