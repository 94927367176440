import React,{useEffect,useState,useContext} from 'react'
import { useForm,useWatch } from 'react-hook-form';
import {useNavigate,Link,useLocation } from "react-router-dom";
import styles from './SignUp.module.css'
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginClient, RegisterClient } from '../../../services/auth-service/clientAccountService';
import AlertMessage from '../../../components/shared/alertMessage/AlertMessage';
import TermsAndCondition from '../../termsAndCondition/TermsAndCondition';
import { categories } from '../../../services/common/constants';
import { LoginInfluen } from '../../../services/auth-service/InflAccountService';
import { UserContext } from '../../../App';



const schema = yup.object().shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup.string().email('Email must be valid').required('Email is required'),
    phone:yup.string().required('Phone is required').min(10,'Phone number must be exactly 10 digits').max(10,'Phone number must be exactly 10 digits'),
    gender: yup.string().required('Please select your gender'),
    category: yup.string().required('Category is required'),
    password:yup.string().required('Password is required')
    .min(8, 'Password must contain atleast eight characters with one uppercase letter, one number and one special character')
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain atleast eight characters with  one uppercase letter, one number and one special character'
    ),
    cpassword:yup.string().oneOf([yup.ref('password'),null],'Password must match'),
    checkbox1: yup.boolean().oneOf([true], 'Above checkbox must be checked*'),
    checkbox2: yup.boolean().oneOf([true], 'Above checkbox must be checked*'),
  })
const ClientSignup = (props) => {
    const navigate = useNavigate();

    const [isDivVisible, setIsDivVisible] = useState(false);
    const {state,dispatch} = useContext(UserContext);
      // const categories = ["Lifestyle"," Health & Fitness", "Food & Nutrition","Fashion","Author/Literature","Spiritual & Motivational",
      // "Education","Career Coach", "Technology" , "Live spaces – Interiors", "Travel & Tourism", "Sports","Professional"
      //  , "Adventure", "Astrology", "Photographer", "Others"]; 
       const {register,handleSubmit,control, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
      })

      const isChecked = useWatch({
        control,
        name: 'checkbox1',
        defaultValue: false // default value htmlFor the checkbox
    });
    useEffect(() => {
      setIsDivVisible(isChecked);
  }, [isChecked]);
    const resetCheckbox = () => {
      setIsDivVisible(false);
  };

const [alertData, setAlertData] = React.useState(null);

const storedata =async (datainp,e)=>{
    e.preventDefault();
    if(!datainp){
        console.log("Error occured");
        return;
    }
    const response = await RegisterClient(datainp);
    //validating the response
    if(response){
        if(response===409){
            setAlertData({
              message: 'Email Already Exists!!',
            });
        }else{
          const {email,password}= datainp;

          const res = await LoginClient({email,password});
          if(res === 400 || !res ){
            setAlertData({
              message: 'Invalid User!!',
            });
         }else{
          dispatch({type:'USER',payload:true})
          if(props.loc){
            navigate(props.loc);
          }else{
          navigate('/clienthome')
          }
         }
          // setAlertData({
          //   message: 'Registration Successful! After Sign In, verify your email in "Profile Settings" to activate your account.',
          // });
        }
    }
    else{
        setAlertData({
          message: 'Registration Unsuccesful!',
        });
    }
}
const handleAlertClose = () => {
  // Close the alert and navigate when the user closes the alert
  setAlertData(null);
  navigate('/signin');
};
  return (
    <div className="parent-container" style={{ overflow: isDivVisible ? 'hidden' : 'auto' }}>
    <div className="inflanar-signin__inner">
<form method='POST' onSubmit={handleSubmit(storedata)}>
<div className="row">
<div className="col-lg-6 col-md-6 col-12">
<div className="form-group inflanar-form-input mg-top-20">
<label>First Name*</label>
<input className="ecom-wc__form-input" type="text" name='firstName' 
required="required" autoComplete="off"
placeholder="First Name"
{...register('firstName')}
/>
<p className='text-danger'>{errors.firstName?.message }</p>
</div>
</div>
<div className="col-lg-6 col-md-6 col-12">
<div className="form-group inflanar-form-input mg-top-20">
<label>Last Name*</label>
<input className="ecom-wc__form-input" type="text" name='lastName'  
autoComplete="off"
placeholder="Last Name"
{...register('lastName')}
/>
<p className='text-danger'>{errors.lastName?.message }</p>
</div>
</div>
<div className="col-lg-4 col-md-4 col-12">
<div className={`form-group mg-top-20`}>
<label className={`${styles.radio_label} mb-0`}>
  <input
    type="radio"
    value="Male"
    name="gender"
    {...register('gender')}
  />
  <span className={styles.radio_text}>
  Male
  </span>
</label>
</div>
</div>
<div className="col-lg-4 col-md-4 col-12">
<div className={`form-group mg-top-20`}>
<label className={`${styles.radio_label} mb-0`}>
  <input
    type="radio"
    value="Female"
    name="gender"
    {...register('gender')}
  />
  <span className={styles.radio_text}>
  Female
  </span>
</label>
</div>
</div>
<div className="col-lg-4 col-md-4 col-12">
<div className={`form-group mg-top-20`}>
<label className={`${styles.radio_label} mb-0`}>
  <input
    type="radio"
    value="No Gender"
    name="gender"
    {...register('gender')}
  />
  <span className={styles.radio_text}>
  Skip
  </span>
</label>
</div>
</div>
<div className='col-lg-12 col-md-12 col-12' style={{marginTop:"-14px"}}>        
                <p className='text-danger'>{errors.gender?.message}</p>
                </div>
<div className="col-lg-6 col-md-6 col-12">
<div className="form-group inflanar-form-input mg-top-20">
<label>Email*</label>
<input className="ecom-wc__form-input" type="email"
autoComplete="off"
name='email'                       
placeholder="Email address"
{...register('email')}
/>
<p className='text-danger'>{errors.email?.message }</p>
</div>
</div>
<div className="col-lg-6 col-md-6 col-12">
<div className="form-group inflanar-form-input mg-top-20">
<label>Phone*</label>
<input className="ecom-wc__form-input" type="Number"
name='phone'     
// value={userRegistration.phone}     
// onChange={handleinput}         
autoComplete="off"
placeholder="Phone"
{...register('phone')}
/>
<p className='text-danger'>{errors.phone?.message }</p>
</div>
</div>
<div className='col-lg-12 col-md-12 col-12'>
<div className="form-group inflanar-form-input mg-top-20">
<label>Category*</label>
<select {...register('category')} defaultValue="">
<option value="" disabled hidden>
  Select a category
</option>
{categories.map((option) => (
  <option key={option} value={option}>
    {option}
  </option>
))}
</select>
<p className='text-danger'>{errors.category?.message}</p>
</div>
</div>
<div className="col-lg-6 col-md-6 col-12">
<div className="form-group inflanar-form-input mg-top-20">
<label>Password*</label>
<input className="ecom-wc__form-input" type="password"
name='password'    
// value={userRegistration.password}     
// onChange={handleinput}                
placeholder="Password"
{...register('password')}
/>
<p className='text-danger'>{errors.password?.message }</p>
</div>
</div>
<div className="col-lg-6 col-md-6 col-12">
<div className="form-group inflanar-form-input mg-top-20">
<label>Confirm Password</label>
<input className="ecom-wc__form-input" type="password"
name='cpassword'
// value={userRegistration.password}     
// onChange={handleinput}                
placeholder="Confirm Password"
{...register('cpassword')}
/>
<p className='text-danger'>{errors.cpassword?.message }</p>
</div>
</div>
<div className="col-12">
<div className="form-group mg-top-20">
<div className="inflanar-signin__check-inline">
<div className="inflanar-signin__checkbox">
<div className="inflanar-signin__checkbox--group">
<input className="inflanar-signin__form-check"  id="checkbox1" name="checkbox1" type="checkbox" {...register('checkbox1')}/>
<label htmlFor="checkbox">Accept <Link to="/terms-condition" target="_blank" rel="noopener noreferrer" className="forgot-pass">Terms and Conditions</Link></label>
</div>
<p className='text-dark'>{errors.checkbox1?.message }</p>
</div>
</div>
</div>
</div>
<div className="col-12">
<div className="form-group ">
<div className="inflanar-signin__check-inline">
<div className="inflanar-signin__checkbox">
<div className="inflanar-signin__checkbox--group">
<input className="inflanar-signin__form-check" id="checkbox2" name="checkbox" type="checkbox" {...register('checkbox2')}/>
<label htmlFor="checkbox"> <Link to="/privacyandpolicy" target="_blank" rel="noopener noreferrer" className="forgot-pass">Privacy Policy</Link></label>
</div>
<p className='text-dark'>{errors.checkbox2?.message }</p>
</div>
</div>
</div>
</div>
<div className="col-12">
<div className="form-group mg-top-40">
<button type="submit" className="inflanar-btn"><span>Create Account</span></button>
</div>
<div className="inflanar-signin__bottom mg-top-20">
<p className="inflanar-signin__text">Have an account ?<Link to='/signin'>Sign In</Link></p>
</div>
</div>
</div>
</form>
</div>
{isDivVisible && (
  <div className="overlay">
    <div className="container" style={{ maxWidth: '1000px',overflowY: "auto",maxHeight: "80%" }}>
    <div className={`card ${styles.influencerCard}`}>
     <div className={`card-body ${styles.cardbody_padding}`}>
          <TermsAndCondition />
          <button onClick={resetCheckbox} className="inflanar-btn"><span>Close</span></button>
      </div>
      </div>
      </div>
      </div>
  )}
{alertData && (
  <div className="overlay">
    {/* You can add an overlay to dim the background if needed */}
    <AlertMessage
      message={alertData.message}
      type={alertData.type}
      onClose={handleAlertClose}
     // onNavigate={handleAlertClose}
    />
  </div>
)}
    </div>
  )
}

export default ClientSignup

