import React,{useState,useEffect} from 'react'
import { fetchInfluencerAccept } from '../../../services/auth-service/requestService';
import AlertMessage from '../../../components/shared/alertMessage/AlertMessage';
import styles from './influenceredit.module.css'



const Acceptsessions = ({initialValues}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [alertData, setAlertData] = React.useState(null); 
    const influencerId = initialValues._id;
    const [requests,setRequests]=useState([])
    const [showText, setShowText] = useState(null);
    
    const InfluencerRequests = async(influencerId,page)=>{
            try {
             // const response = await Promise.all([fetchInfluencerAccept(influencerId,page),fetchInfluencerRequests(influencerId,page),fetchInfluencerAfterTimeComplete(influencerId,page)]);
              
              const response = await fetchInfluencerAccept(influencerId,page);
              if(!response.status === 200){
                setAlertData({
                  message: 'failed to fetch Requests',
                });
              }else if(response===204){
               setAlertData({
                message: 'No Upcoming Sessions',
              });
              }else{ 
              setRequests(response.requests);
              setTotalPages(response.totalPages);}
            } catch (error) {
              console.log(error);
       }
    }
    
    useEffect(() => {
        // Fetch data when the component mounts or when currentPage changes
        InfluencerRequests(influencerId, currentPage);
      }, [currentPage, influencerId]);
      const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
      };
    
      const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure not to go below page 1
      };
      const handlePageClick = (page) => {
        setCurrentPage(page);
      };
    
      const renderPageButtons = () => {
        const buttons = [];
      
        const renderEllipsis = (id) => (
          <span key={`ellipsis-${id}`} className='ml-2 mt-2'>...</span>
        );
      
        for (let i = 1; i <= totalPages; i++) {
          if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 1) {
            // Display first, last, and nearby pages
            buttons.push(
              <button
                className={`btn btn-outline-primary mt-2 ml-2 ${i === currentPage ? 'active' : ''}`}
                key={i}
                onClick={() => handlePageClick(i)}
                disabled={i === currentPage}
              >
                {i}
              </button>
            );
          } else if (buttons[buttons.length - 1] !== renderEllipsis()) {
            // Display ellipsis if not already displayed
            buttons.push(renderEllipsis(i));
          }
        }
      
        return buttons;
      };
        // Handler function for "See" button click
  const handleMouseEnter = (requestId) => {
    if (showText === requestId) {
      setShowText(null); // Hide the tooltip if it's already shown
    } else {
      setShowText(requestId); // Show the tooltip for the clicked row
    }
  };

      const startMessage = ()=>{
        setAlertData({
          message: 'Please join using the session invite sent to your registered email.',
        });
      }

  return (
    <>
    <div className="inflanar-profile-info mg-top-30 inflanar-profile-info__list-container">
    <div className="inflanar-profile-info__head">
      <h3 className="inflanar-profile-info__heading">Upcoming Sessions</h3>
    </div>
    <ul className="inflanar-profile-info__list list-unstyled">
      {/* Header Row */}
      <li className="inflanar-dflex border border-primary-light rounded p-3">
        <div className='col-2'>
          <h4 className="inflanar-profile-info__title text-dark">Name</h4>
        </div>
        <div className='col-2'>
          <h4 className="inflanar-profile-info__title text-dark">Session Date</h4>
        </div>
        <div className='col-1'>
          <h4 className="inflanar-profile-info__title text-dark">Type</h4>
        </div>
        <div className='col-2 text-center'>
          <h4 className="inflanar-profile-info__title text-dark">Fee</h4>
        </div>
        <div className='col-2'>
          <h4 className="inflanar-profile-info__title text-dark">Status</h4>
        </div>
        <div className='col-1'>
          <h4 className="inflanar-profile-info__title text-dark">Purpose</h4>
        </div>
        
        <div className='col-2'>
          <h4 className="inflanar-profile-info__title text-dark ml-4">Action</h4>
        </div>
      </li>
  
      {/* Dynamic Content Rows */}
      {requests.map((request) => (
        <li key={request._id} className="inflanar-dflex border border-primary-light p-3">
          <div className="col-2">
            <h4 className="inflanar-profile-info__title ">
              {request.client.firstName} {request.client.lastName}
            </h4>
          </div>
          <div className="col-2">
            <h4 className="inflanar-profile-info__title text-truncate">
              {new Date(request.selectedSlot.date).toLocaleDateString('en-GB')} <br />
              {request.selectedSlot.startTime} - {request.selectedSlot.endTime}
            </h4>
          </div>
          <div className="col-1">
            <h4 className="inflanar-profile-info__title">
            {request.slotid.meetingType}
           <br></br>
           <span style={{fontSize:"65%"}}>
           Booked:{(request.slotid.total-request.slotid.participants)}/{(request.slotid.total)}
           </span>
         </h4>
          </div>
          <div className="col-2 text-center">
            <h4 className="inflanar-profile-info__title">
            ₹{Math.round((request.amount - (Math.round(request.platformfee * 0.18*100)/100 + request.platformfee))*100)/100}
            </h4>
          </div>
          <div className="col-2">
            <h4 className="inflanar-profile-info__title">
              {request.status === 'accepted' ? (
                <button className="btn btn-outline-success" disabled>Accepted</button>
              ) : (
                <button className="btn btn-outline-info" disabled>Completed</button>
              )}
            </h4>
          </div>
          {showText === request._id && ( 
            <div className={`${styles.tooltip} mg-btm-100 `} >
              <textarea
                className="form-control" // Apply Bootstrap form-control class for styling
                value={request.puposetext}
                readOnly // Make the textarea read-only
                rows={6} // Adjust rows as needed
              />
            </div>
          )}
          <div className="col-1 d-flex align-items-center">
          <button
          className="btn btn-outline-primary ml-2"
          onClick={() => handleMouseEnter(request._id)} 
        >
          See
        </button> 
          </div>        
          <div className="col-2 d-flex align-items-center">
            <button className="btn btn-outline-primary ml-4" onClick={() => startMessage(request._id)}>Start</button>
          </div>
        </li>
      ))}
    </ul>
    <div className='text-right'>
      <button className='btn btn-outline-primary mt-2' onClick={handlePrevPage} disabled={currentPage === 1}>
        Previous Page
      </button>
      {renderPageButtons()}
      <button className='btn btn-outline-primary mt-2 ml-2' onClick={handleNextPage} disabled={currentPage === totalPages}>
        Next Page
      </button>
    </div>
  </div>
  
  
{alertData && (
  <div className="overlay">
    {/* You can add an overlay to dim the background if needed */}
    <AlertMessage
      message={alertData.message}
      type={alertData.type}
      onClose={() => setAlertData(null)}
    />
  </div>
)}
</>
  )
}

export default Acceptsessions