import React from 'react'
import { faSmileBeam } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';


const Errorpage = () => {
  return (
    <>
        <div className='text-center' style={{marginTop:"100px"}}>
          <div><h1 className='text-info fs-100'>Not Found</h1>
          <h1 className='text-info fs-160'>404</h1>
          <FontAwesomeIcon className='fs-160 sadcry' icon={faSmileBeam}/>
          <h1 className='text-muted text-info'>The page you are looking for is not available...</h1>     
          <Link to="/" >
          <button className='btn btn-outline-danger'>Redirect to home</button>
          </Link>
          </div>     
        </div>  
       
    </>
  )
}

export default Errorpage