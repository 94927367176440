import React,{useState,useEffect} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } 
from "@fortawesome/free-solid-svg-icons";
import { UpdateRequestAfterTwo, confirmMail, fetchInfluencerAfterTimeComplete, fetchInfluencerRequests, handleDeleteRequest, handleUpdateRequest } from '../../../services/auth-service/requestService';
import AlertMessage from '../../../components/shared/alertMessage/AlertMessage';
import { mailbodyDecline } from '../../../utils/common';
import { accesToken } from '../../../services/auth-service/teamsAPI';
import styles from './influenceredit.module.css'


const Pendingsessions = ({initialValues}) => 
{
const [alertData, setAlertData] = React.useState(null);
const [currentPage, setCurrentPage] = useState(1);
const [totalPages, setTotalPages] = useState(1);

const influencerId = initialValues._id;
const [requests,setRequests]=useState([])
const [loading, setLoading] = useState(false);
const [showText, setShowText] = useState(null);

const InfluencerRequests = async(influencerId,page)=>{
        try {
          //const response = await fetchInfluencerRequests(influencerId,page);
          const response = await Promise.all([UpdateRequestAfterTwo(),fetchInfluencerRequests(influencerId,page),fetchInfluencerAfterTimeComplete(influencerId,page)]);
          //await UpdateRequestAfterTwo(influencerId)
          
          if (response.some((res) => !res === 200)) {
            setAlertData({
              message: 'Failed to get Response',
            });
          }else if (response[1] === 204) {
           setAlertData({
            message: 'No Request for Now',
          });
          }else{
          setRequests(response[1].requests);
          setTotalPages(response[1].totalPages);}
        } catch (error) {
          console.log(error);
   }
}

useEffect(() => {
    // Fetch data when the component mounts or when currentPage changes
    InfluencerRequests(influencerId, currentPage);
  }, [currentPage, influencerId]);

  const markAccepted = async (_id) => {
    try {
      setLoading(true); // Set loading to true before calling accesToken
      // Call handleUpdateRequest (will the session from pending to accepted) and accesToken( this will create teams meeting)
       const response = await Promise.all([handleUpdateRequest(_id, 'accepted','Payment received'), accesToken(_id)]);
      // const response = await accesToken(_id);
      // Check if any of the responses is 204
      if (response.some((res) => res === 204)) {
        setAlertData({ message: 'No Requests For Now' });
      }
      // Filter out the request with the given _id
      setRequests((prevRequests) => prevRequests.filter((request) => request._id !== _id));
      setCurrentPage(1);

    } catch (error) {
      console.error('Error marking request as accepted:', error);
      setAlertData({ message: 'Failed to mark request as accepted' });
    } finally {
      setLoading(false); // Set loading to false after all operations
    }
  };

  // Handler function for "See" button click
  const handleMouseEnter = (requestId) => {
    if (showText === requestId) {
      setShowText(null); // Hide the tooltip if it's already shown
    } else {
      setShowText(requestId); // Show the tooltip for the clicked row
    }
  };

  // Render loading spinner if loading is true
  if (loading) {
    return (// Display spinner while waiting for the response
    <div className="spinner-container">
    <FontAwesomeIcon
                    className="spinner"
                    icon={faSpinner}
                  />
                  </div>)
  }


const deleteEntry =async(_id)=>{
    try {
      
        const response = await handleUpdateRequest(_id, 'decline','Requesting refund');
        if(!response.status === 200){
          setAlertData({
            message: 'Failed to get Response',
          });
        }else if(response===204){
         setAlertData({
          message: 'No Requests for now',
        });
        }
        const mail = mailbodyDecline({razorpay_order_id:response.request.razorpay_order_id})
        const email = response.request.client.email 
        await confirmMail({ ...mail,email});
        setRequests(requests.filter(request => request._id !== _id));
        setCurrentPage(1);
      } catch (error) {
        console.log(error);
 }
}

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure not to go below page 1
  };
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const renderPageButtons = () => {
    const buttons = [];
  
    const renderEllipsis = (id) => (
      <span key={`ellipsis-${id}`} className='ml-2 mt-2'>...</span>
    );
  
    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || Math.abs(i - currentPage) <= 1) {
        // Display first, last, and nearby pages
        buttons.push(
          <button
            className={`btn btn-outline-primary mt-2 ml-2 ${i === currentPage ? 'active' : ''}`}
            key={i}
            onClick={() => handlePageClick(i)}
            disabled={i === currentPage}
          >
            {i}
          </button>
        );
      } else if (buttons[buttons.length - 1] !== renderEllipsis()) {
        // Display ellipsis if not already displayed
        buttons.push(renderEllipsis(i));
      }
    }
  
    return buttons;
  };


  return (
    <>
    <div className="inflanar-profile-info mg-top-30 inflanar-profile-info__list-container">
    <div className="inflanar-profile-info__head">
      <h3 className="inflanar-profile-info__heading">Sessions Requests</h3>
    </div>
      <ul className="inflanar-profile-info__list list-unstyled row">
        {/* Header Row */}
        <li className="inflanar-dflex border border-primary-light rounded p-3 col-lg-12">
          <div className='col-lg-3 col-md-3 col-sm-3 col-3'>
            <h4 className="inflanar-profile-info__title text-dark">Name</h4>
          </div>
          <div className='col-lg-2 col-md-2 col-sm-2 col-2'>
            <h4 className="inflanar-profile-info__title text-dark">Session Date</h4>
          </div>
          <div className='col-lg-1 col-md-1 col-sm-1 col-1'>
            <h4 className="inflanar-profile-info__title text-dark">Type</h4>
          </div>
          <div className='col-lg-1 col-md-1 col-sm-2 col-1'>
            <h4 className="inflanar-profile-info__title text-dark">Fee</h4>
          </div>       
          <div className='col-lg-2 col-md-2 col-sm-2 col-2'>
            <h4 className="inflanar-profile-info__title text-dark">Purpose</h4>
          </div>
          <div className='col-lg-3 col-md-3 col-sm-3 col-3'>
            <h4 className="inflanar-profile-info__title text-dark">Action</h4>
          </div>
        </li>
  
        {/* Dynamic Content Rows */}
        {requests.map((request) => (
          <li key={request._id} className="inflanar-dflex border border-primary-light p-3 col-lg-12">
            <div className="col-lg-3 col-md-3 col-sm-3 col-3">
              <h4 className="inflanar-profile-info__title ">
                {request.client.firstName} {request.client.lastName}
              </h4>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-2">
              <h4 className="inflanar-profile-info__title text-truncate">
                {new Date(request.selectedSlot.date).toLocaleDateString('en-GB')} <br />
                {request.selectedSlot.startTime} - {request.selectedSlot.endTime}
              </h4>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 col-1 ">
              <h4 className="inflanar-profile-info__title">
                 {request.slotid.meetingType}
                <br></br>
                <span style={{fontSize:"65%"}}>
                Booked:{(request.slotid.total-request.slotid.participants)}/{(request.slotid.total)}
                </span>
                </h4>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-2 col-1">
              <h4 className="inflanar-profile-info__title">
              ₹{Math.round((request.amount - (Math.round(request.platformfee * 0.18*100)/100 + request.platformfee))*100)/100}
              </h4>
            </div>                 
            {showText === request._id && ( 
                <div className={`${styles.tooltip} mg-btm-100 `} style={{marginLeft:"290px"}}>
                  <textarea
                    className="form-control" // Apply Bootstrap form-control class for styling
                    value={request.puposetext}
                    readOnly // Make the textarea read-only
                    rows={6} // Adjust rows as needed
                  />
                </div>
              )}
            <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                <button
                  className="btn btn-outline-primary ml-2"
                  onClick={() => handleMouseEnter(request._id)} 
                 // onMouseLeave={handleMouseLeave}
                >
                  See
                </button>             
              </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 d-flex justify-content-end align-items-center">
              <button className="btn btn-success ml-2" onClick={() => markAccepted(request._id)}>Approve</button>
              <button className="btn btn-danger ml-2" onClick={() => deleteEntry(request._id)}>Decline</button>
            </div>
          </li>
        ))}
      </ul>
    <div className='text-right'>
      <button className='btn btn-outline-primary mt-2' onClick={handlePrevPage} disabled={currentPage === 1}>
        Previous Page
      </button>
      {renderPageButtons()}
      <button className='btn btn-outline-primary mt-2 ml-2' onClick={handleNextPage} disabled={currentPage === totalPages}>
        Next Page
      </button>
    </div>
  </div>
  
{alertData && (
  <div className="overlay">
    {/* You can add an overlay to dim the background if needed */}
    <AlertMessage
      message={alertData.message}
      type={alertData.type}
      onClose={() => setAlertData(null)}
    />
  </div>
)}
</>
  )
}

export default Pendingsessions 