import React, { useState, useEffect,useRef } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import styles from './markfreeCalender.module.css';
import { getimeslot, markTimeSlot,bookedtimeslot } from '../../services/auth-service/timeSlot';
import AlertMessage from '../../components/shared/alertMessage/AlertMessage';
import { useLocation } from 'react-router-dom';
import HttpService from "../../services/http-service/HttpService";
import { handleGenerateUrl } from '../../webHooks/short';

const MarkFreeSlots = ({ influencerId, onClose }) => {
  const location = useLocation();
  const [selectedDates, setSelectedDates] = useState([]);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [meetingType, setMeetingType] = useState('Single');
  const [participants, setParticipants] = useState(1);
  const [alertData, setAlertData] = React.useState(null);
  const [lastFetchedDates, setLastFetchedDates] = useState([]);
  const isFirstRender = useRef(true);
  const [showRepeatModal, setShowRepeatModal] = useState(false);
  const [confirmRepeat, setConfirmRepeat] = useState(false);
  const [pendingMarking, setPendingMarking] = useState(null);

  const [shortenedUrl, setShortenedUrl] = useState('');
  const [error, setError] = useState('');

  // const handleGenerateUrl = async () => {
  //   try {
  //     // Construct the full URL
  //     const baseUrl = process.env.REACT_APP_BASE_URL;
  //     const fullUrl = `${baseUrl}/checkavailability/${influencerId}`;

  //     // Send the URL to the backend to shorten
  //     const response = await HttpService.post(`${process.env.REACT_APP_API_URL}/api/shorten-url`, { longUrl: fullUrl });
      
  //     // Get the shortened URL
  //     setShortenedUrl(response.data.link);
  //   } catch (err) {
  //     console.error('Error generating Bitly URL:', err);
  //     setError('Failed to generate shortened URL. Please try again.');
  //   }
  // };
// Fetch selected dates initially when influencerId changes
useEffect(() => {
  const fetchSelectedDates = async () => {
    try {
      const response = await bookedtimeslot(influencerId);
      const existingDates = response.data.map((slot) => new Date(slot.date));
      setSelectedDates(existingDates);
      setLastFetchedDates(existingDates); // Store the fetched dates to avoid infinite loops
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const fullUrl = `${baseUrl}/checkavailability/${influencerId}`
     //const url = await handleGenerateUrl(influencerId);
      setShortenedUrl(fullUrl);
    } catch (error) {
      console.error('Error fetching selected dates:', error);
    }
  };
  fetchSelectedDates();
}, [influencerId,shortenedUrl]);

// Re-fetch selected dates if influencerId or selectedDates change, but avoid infinite loop
useEffect(() => {
  if (isFirstRender.current) {
    isFirstRender.current = false;
    return;
  }

  const fetchSelectedDates = async () => {
    try {
      const response = await bookedtimeslot(influencerId);
      const existingDates = response.data.map((slot) => new Date(slot.date));
      
      // Update selectedDates only if it is different from the last fetched data
      if (JSON.stringify(existingDates) !== JSON.stringify(lastFetchedDates)) {
        setSelectedDates(existingDates);
        setLastFetchedDates(existingDates); // Update the last fetched dates to prevent loops
      }
    } catch (error) {
      console.error('Error fetching selected dates:', error);
    }
  };

  fetchSelectedDates();
}, [influencerId, selectedDates, lastFetchedDates]);

  const handleMeetingTypeChange = (e) => {
    const selectedType = e.target.value;
    setMeetingType(selectedType);

    // Set participants to 5 when switching to 'multiple' meeting type
    if (selectedType === 'Group') {
      setParticipants(5);
    } else {
      // Reset participants to 1 for 'single' meeting type
      setParticipants(1);
    }
  };

  const handleParticipantsChange = (e) => {
    setParticipants(parseInt(e.target.value, 10));
  };
  const handleDateClick = (date) => {
    const today = new Date();
    const oneDayAhead = new Date(today.getTime());

    if (date < oneDayAhead) {
      return; // Prevent selecting past dates
    }

    // Set both start and end to the clicked date immediately
    setDateRange({ start: date, end: date });
};


  const handleStartTimeChange = (e) => {
    const selectedStartTime = e.target.value;
    setStartTime(selectedStartTime);

    // Calculate end time (30 minutes later)
    const [startHour, startMinute] = selectedStartTime.split(':').map(Number);
    const endHour = startHour + Math.floor((startMinute + 30) / 60);
    const endMinute = (startMinute + 30) % 60;

    // Format end time in 'HH:mm' format
    const formattedEndTime = `${String(endHour).padStart(2, '0')}:${String(endMinute).padStart(2, '0')}`;
    setEndTime(formattedEndTime);
  };

//   const handleMarkFreeClick = async () => {
//     if (!dateRange.start || !dateRange.end || !startTime || !endTime) {
//         alert('Please select a valid date range and time slots.');
//         return;
//     }
//     const shouldRepeat = window.confirm("Do you want to add the same time slot for the next 2 months on this day?");
//     try {
//         const istStartDate = moment(dateRange.start).tz('Asia/Kolkata').toDate();
//         const istEndDate = moment(dateRange.end).tz('Asia/Kolkata').toDate();
//           // **NEW FUNCTIONALITY: Ask user if they want to repeat the slot for 2 months**
       
//         // const dates = generateDateRange(istStartDate, istEndDate);
//         let dates;

//         if (shouldRepeat) {
//             dates = generateRecurringDates(istStartDate, 2); // Generate dates for the next 2 months
//         } else {
//             dates = generateDateRange(istStartDate, istEndDate); // Only selected date range
//         }

//         const status = await markTimeSlot(influencerId, dates, startTime, endTime, meetingType, participants);

//         if (status === 207) {
//             setAlertData({
//                 message: 'Please select a time slot that is at least 24 hours from now.',
//             });
//         } else if (status === 208) {
//             setAlertData({
//                 message: 'The slot of selected time already exists on this date',
//             });
//         } else {
//             const newSelectedDates = [...selectedDates, ...generateDateRange(istStartDate, istEndDate)];
//             setDateRange({ start: null, end: null });
//             setStartTime('');
//             setEndTime('');
//             setSelectedDates(newSelectedDates);

          
//             // if (shouldRepeat) {
//             //     const recurringDates = generateRecurringDates(istStartDate, 2);
//             //     await markTimeSlot(influencerId, recurringDates, startTime, endTime, meetingType, participants);
//             //     setSelectedDates([...selectedDates, ...recurringDates.map(d => new Date(d.date))]);
//             // }
//         }
//     } catch (error) {
//         console.error('Error marking dates as free:', error);
//     }
// };

const handleMarkFreeClick = async () => {
    if (!dateRange.start || !dateRange.end || !startTime || !endTime) {
        alert('Please select a valid date range and time slots.');
        return;
    }

    // **SHOW MODAL INSTEAD OF window.confirm**
    setShowRepeatModal(true);
};

// Function to handle modal confirmation
const handleConfirmRepeat = async (shouldRepeat) => {
    setShowRepeatModal(false);
    
    try {
        const istStartDate = moment(dateRange.start).tz('Asia/Kolkata').toDate();
        const istEndDate = moment(dateRange.end).tz('Asia/Kolkata').toDate();
        let dates;

        if (shouldRepeat) {
            dates = generateRecurringDates(istStartDate, 2); // Generate dates for the next 2 months
        } else {
            dates = generateDateRange(istStartDate, istEndDate); // Only selected date range
        }

        const status = await markTimeSlot(influencerId, dates, startTime, endTime, meetingType, participants);

        if (status === 207) {
            setAlertData({
                message: 'Please select a time slot that is at least 24 hours from now.',
            });
        } else if (status === 208) {
            setAlertData({
                message: 'The slot of selected time already exists on this date',
            });
        } else {
            const newSelectedDates = [...selectedDates, ...generateDateRange(istStartDate, istEndDate)];
            setDateRange({ start: null, end: null });
            setStartTime('');
            setEndTime('');
            setSelectedDates(newSelectedDates);

            // if (shouldRepeat) {
            //     const recurringDates = generateRecurringDates(istStartDate, 2);
            //     await markTimeSlot(influencerId, recurringDates, startTime, endTime, meetingType, participants);
            //     setSelectedDates([...selectedDates, ...recurringDates.map(d => new Date(d.date))]);
            // }
        }
    } catch (error) {
        console.error('Error marking dates as free:', error);
    }
};

const generateRecurringDates = (startDate, months) => {
  const dates = [];
  let currentDate = moment(startDate);

  for (let i = 0; i < months * 4; i++) { // Approx 8 weeks (assuming 4 weeks per month)
      dates.push({
          date: currentDate.toISOString(),
          startTime: startTime,
          endTime: endTime,
      });
      currentDate.add(7, 'days'); // Move to the next week's same day
  }
  return dates;
};


  const generateDateRange = (start, end) => {
    const dates = [];
    let currentDate = moment(start);
  
    while (currentDate <= end) {   
      dates.push({
        date: currentDate.toISOString(),
        startTime: startTime,
        endTime: endTime,
      });
      currentDate.add(1, 'day');
    }
    return dates;
  };

  const isDateBeforeToday = (date) => {
    const today = new Date();
    const OneDaysAhead = new Date(today.getTime()); // Set date to 12 hours (3 days) ahead
    return date < OneDaysAhead;
  };
  
  const isDateBooked = (date) => {
    const formattedDate = new Date(date).toDateString();
    return selectedDates.some((selectedDate) => new Date(selectedDate).toDateString() === formattedDate);
  };

  return (
    <>
    <div className="container mt-5 " style={{overflowY: "auto",maxHeight: "80%" }}>
      <div className="card">
        <span className={`btn btn-outline-dark close-btn ${styles.closebutton} mb-3`} onClick={onClose}>&times;</span>
        <div className="card-header">
          <h2 className="mb-0">Mark your calendar for available slots</h2>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-8 card p-4 r-4">
              <Calendar
                onClickDay={handleDateClick}
                tileClassName={({ date }) => {
                  let className = '';
                  if (isDateBeforeToday(date)) {
                    className = `${styles.beforetoday}`;
                  } else if (isDateBooked(date)) {
                    className = `${styles.bookeddate}`;
                  } else {
                    className = `${styles.selecteddate}`;
                  }
                  return className;
                }}
              />
              <div className='row mt-4 w-100'>
              <div className='col-md-4'>
                <label>Session Type:</label>
                <select
                  className="form-control mb-3"
                  value={meetingType}
                  onChange={handleMeetingTypeChange}
                >
                  <option value="Single">Single (1:1)</option>
                  <option value="Group">Group </option>
                </select>
              </div>
              {meetingType === 'Group' && (
                <div className='col-md-4'>
                  <label>Session Participants:</label>
                  <select
                    className="form-control mb-3"
                    value={participants}
                    onChange={handleParticipantsChange}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                  </select>
                </div>
              )}
              {shortenedUrl && (
                <div className='col-md-12'>
                  <label className='font-weight-bold'>Your Calender Link</label>
                  <span style={{fontSize: '80%'}}> (for share on social media)</span>
                  <div className="input-group mb-3">
                  <span
                  className={`shortened-url mb-1 mt-2 mr-2 ${styles.shortenedUrl}`}
                  title={shortenedUrl} // Show full URL on hover
                  >
                    {shortenedUrl}
                  </span>
                  <button
                    className="btn btn-outline-secondary "
                    
                    onClick={() => navigator.clipboard.writeText(shortenedUrl)}
                    title="Copy to clipboard"
                  >
                    Copy
                  </button>
                  </div>
                </div>
              )}
            </div>
              <p className='mt-2 text-danger'>Please contact admin at{' '}
                <a className='text-primary' href={`mailto:support@inkorero.com`}>support@inkorero.com</a> for any support.</p>
            </div>
            {dateRange.start && dateRange.end && (
              <div className="col-md-4">
                <label>Start Time:</label>
                <input
                  type="time"
                  className="form-control mb-3"
                  value={startTime}
                  onChange={handleStartTimeChange}
                />
                <label>End Time:</label>
                <input
                  type="time"
                  className="form-control mb-3"
                  value={endTime}
                  readOnly
                />
                <button className="btn btn-primary" onClick={handleMarkFreeClick}>
                  Mark Free
                </button>
              </div>
            )}
            
          </div>
        </div>
      </div>
    </div>
    {alertData && (
      <div className="overlay">
        {/* You can add an overlay to dim the background if needed */}
        <AlertMessage
          message={alertData.message}
          type={alertData.type}
          onClose={() => setAlertData(null)}
        />
      </div>
    )}
    {showRepeatModal && (
      <div className={`${styles.modal_overlay}`}>
        <div className={`${styles.modal_content}`}>
          <h5>Repeat Time Slot for 2 Months?</h5>
          <p>Do you want to apply the same time slot for the next 2 months on this day?</p>
          <div className={`${styles.modal_actions}`}>
            <button className={`btn inflanar-btn inflanar-btn--header`} onClick={() => handleConfirmRepeat(true)}>
              Yes
            </button>
            <button className={`btn inflanar-btn inflanar-btn--header ${styles.btn_secondary}`}  onClick={() => handleConfirmRepeat(false)}>
              No
            </button>
          </div>
        </div>
      </div>
    )}
    
    </>
  );
};

export default MarkFreeSlots;
